body {
    background-color: #fff;
}

h1 {
  font-size: 48px;
}

.app {
    font-size: 1rem;
}

@font-face {
    font-family: Montserrat;
    src: url(./fonts/Montserrat-Regular.ttf);
}

.auth-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.navbar {
    margin-bottom: 40px;
    border-radius: 0;
    height: 200px;
    vertical-align: middle;
    align-items: center;
    background-color: #fff;
}

.navbar h1 {
    color: #222;
    font-family: "Montserrat", sans-serif;
    font-size: 64px;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15%;
    padding-top: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;
}

#script {
    font-family: nanum_pen_script, sans-serif;
    display: inline-block;
}

.navbar li {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
}

.navbar li a {
    color: #222;
}

.navbar-nav {
    display: inline-block;
    margin-top: 40px;
    margin-right: 15%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.navbar .navbar-nav a:hover {
    background-color: #ddd;
}

.navbar .navbar-nav .navbar__link--active {
    color: #fff;
    background-color: #222;
}

.navbar .navbar-nav .navbar__link--active:hover {
    color: #fff;
    background-color: #222;
}

#navbar-underline {
    background-color: #222;
    height: 10px;
    width: 100%;
    display: flex;
}

#social-bar {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}

#social-bar ul {
    display: flex;
    list-style-type: none;
    width: 100%;
    padding: 0;
    justify-content: center;
}

#social-bar li {
    display: inline-block;
    padding: 0 15px;
}

#social-bar i {
    color: #222;
}

#social-bar a:hover {
    text-decoration: none;
    background-color: #fff;
}

#homepage {
    background-color: #fafafa;
    margin: 0;
}

h2 {
    font-size: 100%
}

.welcome-message {
    font-size: 1.8rem;
}

.center-block {
    float: none;
}

#coming-soon {
    font-size: 1.8rem;
}

.instagram-content h3 {
    margin-bottom: 40px;
    padding-top: 25px;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 50px, 0;
    color: #5c5b5b;
}

.image-container {
    margin: 4% 0;
    padding: 0;
}

.image-container img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.form-img {
    cursor: pointer;
}

.active {
    outline: 8px solid cadetblue;
}

footer {
    height: 50px;
    background-color: #222;
    color: #fff;
    text-align: center;
    display: block;
}

footer p {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-top: 15px;
}