@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.ttf);
}

a {
  color: rgb(117, 116, 116);
}

a:hover {
  color: black;
}

h1 {
  display: flex;
  justify-content: center;
}

.recipe-list {
  width: 60%;
  margin: 30px auto;
}

.recipe-image-container {
  margin-right: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  width: 25%;
  display: inline-block;
  position: relative;
}

.recipe-image-dummy {
  margin-top: 100%;
}

.recipe-image {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black; */
  width: 100%;
  object-fit: cover;
}

.list-group-item {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.recipe-title {
  color: rgb(117, 116, 116);
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.recipe-intro {
  color: rgb(117, 116, 116);
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  text-decoration: none;
  font-size: 18px;
}

/* RECIPE CARD */

.recipe-card {
  text-align: left;
  background-color: #ddd;
} 

.recipe-card .row {
  margin-left: 30px;
  margin-right: 30px;
}

.recipe-card-title {
  font-size: 48px;
  background-color: transparent;
}

.recipe-card-divider {
  border-style: solid;
  border-width: 1px;
  
}

.recipe-card-sub-header {
  margin-top: 10px;
  padding: 0;
}

.recipe-card p {
  color: black;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.4;
}

.recipe-card h4 {
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
}

.recipe-card-image-container {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
  max-width: 100%;
  width: 60%;
  display: inline-block;
  position: relative;
}

.recipe-card-image {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

#recipe-card-tags {
  font-weight: 200;
  margin-bottom: 20px;
}

.recipe-interactions {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 1.25em;
}

.likes-container {
  margin-bottom: 20px;
}

.like-button-container {
  display: inline-block;
  vertical-align: middle;
}

.likes-count-container {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
}

.likes {
  font-size: 1.5rem;
}

.comment-count {
  font-weight: 200;
  color:rgb(120, 120, 120);
}

.comments-container {
  padding-left: 20px;
}

.comments-container h4 {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
}

.comments-container ul {
  list-style-type: none;
  padding: 0;
}

.comment {
  position: relative;
}

.author-name {
  font-weight: bold;
}

.comment-date {
  font-weight: 200;
}

.author-avatar {
  left: 0;
  position: absolute;
  top: 0;
  background-color: rgba(95, 158, 160, 0.7);
  height: 40px;
  width: 40px;
  border-radius: 60px;
  text-align: center;
}

.author-init {
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color:rgb(80, 80, 80);
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
}

.comment-text {
  display: inline-block;
  padding-left: 52px;
}

.comment-form {
  margin-top: 10px;
  padding: 20px;
  background-color: #fff;
  border: solid 1px rgb(63, 63, 63);
}

.comment-input {
  width: 100%;
  background-color: #fafafa;
  margin-bottom: 10px;
  box-sizing: border-box;
}

#comment-submit {
  height: 32px;
  float: right;
  text-transform: uppercase;
}